import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faEuroSign} from "@fortawesome/free-solid-svg-icons";
import {convertPrice} from "../../../utils/helper.service";

const AccIsConnected = (props) => {

    return (
<div>

    <div className={'box'}>
        <p>Piesaistā klienta konta dati:</p>
        <p> <b>{ props.user.name }</b></p>
        <p><span className="icon  mr-1"><FontAwesomeIcon icon={faPhone}/></span> <b>+{ props.user.phone_with_code }</b></p>
        <p><span className="icon  mr-1"><FontAwesomeIcon icon={faEnvelope}/></span> <b>{ props.user.email }</b></p>
        <p><span className="icon  mr-1"><FontAwesomeIcon icon={faEuroSign}/></span> WashCar kredīti jūsu kontā:<b> { convertPrice(props.user.credits) } </b></p>
    </div>

    <div className={'row'}></div>

    <div className={'column is-half'}>

        {props.canPayoutWashCarCredits ?   <form onSubmit={props.handleWithDrawCredits} className={'box'}>

            <div className="field">
                <label className="label">Konvertējamā summa centos</label>
                <div className="control">
                    <input  min="1" max="5" required className="input" type="number" value={props.withDrawRequestAmount}
                            onChange={(e) => props.setWithDrawRequestAmount(e.target.value)}/>
                </div>
            </div>

            <div className="field is-grouped">
                <div className="control">
                    <button  type="submit" className="button is-link">Kovertēt
                    </button>
                </div>
            </div>

        </form> : null}
    </div>




</div>
    )

};

export default AccIsConnected;
