import React from "react";
import CustomPromoCode from "./custom-promo-code";

const PromoCode = (props) => {

    return (
        <div className={'columns'}>
            <div className={'column'}>
                <div className="box">

                    <div className="heading"> Jūsu promo kods</div>

                    <div className={'columns'}>

                        <div className={'column'}>
                            <div className={'notification is-success'}>

                                <div className="title">{props.defaultPromoCode.code}</div>
                                <button type={'button'} onClick={() => {props.copyPromoToClipboard()}} className={'button is-info'}>Kopēt starpliktuvē</button>
                            </div>
                        </div>

                        {props.customPromoCodeStatus === 'not_submitted'  ?  <div className={'column'}>
                            <CustomPromoCode promoCode={props.promoCode} setPromoCode={props.setPromoCode} handleSubmitPromoCode={props.handleSubmitPromoCode} submitDisabled={props.submitDisabled} />
                        </div> : null}

                        {props.customPromoCodeStatus === 'waiting_accept'  ?  <div className={'column'}>
                            <div className={'notification is-warning'}>Jūsu personalizētais promo kods ir iesniegts izskatīšanai adminstratoram. Tīklīdz tas tiks apstiprināts, mēs jūs par to informēsim.</div>
                        </div> : null}


                    </div>



                </div>



            </div>
        </div>
    )

};

export default PromoCode;