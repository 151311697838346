import * as React from "react"
import {navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {isLoggedIn} from "../utils/auth.service";
import LoadingSpinner from "../components/loading-spinner";
import authHeader from "../utils/auth-header";
import {convertPrice} from "../utils/helper.service";
import Accumulation from "../components/dashboard/accumulation/accumulation";
import StripeLogo from "../components/dashboard/stripe-logo";
import PayoutSettings from "../components/dashboard/payout-settings";
import PromoCode from "../components/dashboard/promo-code";
import Contract from "../components/dashboard/contract";
import toast, {Toaster} from "react-hot-toast";

const Dashboard = () => {

    const [loading, setLoading] = useState(true);
    const [defaultPromoCode, setDefaultPromoCode] = useState([]);
    const [partner, setPartner] = useState([]);
    const [user, setUser] = useState([]);
    const [allTimeProfitInCents, setAllTimeProfitInCents] = useState(null);
    const [promoCode, setPromoCode] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [canPayoutWashCarCredits, setCanPayoutWashCarCredits] = useState(false);
    const [customPromoCodeStatus, setCustomPromoCodeStatus] = useState('not_submitted');

    const [selectedPayoutOption, setSelectedPayoutOption] = useState('manual');
    const [withDrawRequestAmount, setWithDrawRequestAmount] = useState(0);

    useEffect(() => {

        if (!isLoggedIn()) {
            return navigate('/');
        }

        loadDashboardData();

    }, [])


    const handleSubmitPayoutSettings = async (e) => {

        e.preventDefault();
        let payoutType = e.target.value;

        try {

            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    payout_type: payoutType,

                })
            };

            fetch(process.env.WASHCAR_API_URL + "/partners/set-payout-method", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {


                    if (resultData.status === 'Success') {
                        setSelectedPayoutOption(payoutType);
                        toast.success(resultData.alert.text);

                    } else {
                        toast.error(resultData.alert.text);
                    }

                    setLoading(false);


                }) // set data for the number of stars

        } catch (err) {
            console.log(err);
        }

    };


    const handleSubmitPromoCode = async (e) => {

        e.preventDefault();
        setSubmitDisabled(true);
        try {


            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    custom_promo_code: promoCode,

                })
            };

            fetch(process.env.WASHCAR_API_URL + "/partners/post-want-custom-account", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    console.log(resultData.status);

                    if (resultData.status === 'Success') {
                        toast.success(resultData.alert.text);

                    } else {
                        toast.error(resultData.alert.text);
                    }
                    setCustomPromoCodeStatus('waiting_accept');
                    setSubmitDisabled(false);


                }) // set data for the number of stars

        } catch (err) {
            console.log(err);
        }

    };


    const loadDashboardData = () => {

        setLoading(true);

        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };


        fetch(process.env.WASHCAR_API_URL + "/partners/get-dashboard", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {

                setDefaultPromoCode(resultData.data.default_promo_code);
                setPartner(resultData.data.partner);
                setUser(resultData.data.user);
                setCustomPromoCodeStatus(resultData.data.custom_promo_code_status);
                setAllTimeProfitInCents(resultData.data.all_time_profit_in_cents);
                setSelectedPayoutOption(resultData.data.partner.payout_type);

                if(parseInt(resultData.data.partner.total_income_in_cents) > 0){
                    setCanPayoutWashCarCredits(true);
                }


                setLoading(false);

            }) //
    }



    const handleWithDrawCredits = (e) => {

       // e.preventDefault();

        try {


            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    requested_amount_in_cents: withDrawRequestAmount,

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/partners/post-washcar-credits-withdraw", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabled(false);
                    setLoading(false)

                    if(resultData.status === 'Error'){
                        toast.error(resultData.alert.text)
                    }
                    else{
                        toast.success(resultData.alert.text);
                    }


                }) // set data for the number of stars



        } catch (err) {
            console.log(err);
        }


    };


    const copyPromoToClipboard = () => {

        //toast.success('PROMO kods nokopēts!');
        navigator.clipboard.writeText(defaultPromoCode.code);

    }


    const connectStripe = () => {


        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),

        };

        fetch(process.env.WASHCAR_API_URL + "/partners/add-stripe-account", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                window.location.replace(resultData.data.redirect_url);
            }) //

    }

    return (
        <Layout>

            <Seo title="Panelis"/>
            <h1 className="title">Panelis</h1>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-center"><LoadingSpinner/></div> : <div>


                {parseInt(partner.is_stripe_connected) === 0 ? <div className={'box notification is-warning'}>
                    <div className={'columns'}>
                        <div className={'column is-four-fifths'}>
                            <p className={'has-text-black'}>Piesaistiet savu bankas kontu, ja vēlaties peļņas
                                izmaksu <b>EUR</b>!</p>
                            <p>Mēs izmantojam <a rel={'noreferrer'} target={'_blank'}
                                                 href={'https://stripe.com'}>Stripe</a>, lai nodrošinātu, automātisku
                                naudas pārskaitījumu uz Jūsu bankas kontu.</p>
                        </div>
                        <div className={'column'}>
                            <div><StripeLogo/></div>
                        </div>

                    </div>

                    <button className={'button is-info mt-4'} onClick={connectStripe}>Piesaistīt bankas kontu
                    </button>
                </div> : null}



                {partner.contract_signed_at === null ? <Contract/> : null}


                <div className={'columns'}>

                    <div className={'column'}>
                        <div className="box notification is-info">
                            <div className="heading">Uzkrājums</div>
                            <div className="title">{convertPrice(partner.total_income_in_cents)}</div>

                        </div>
                    </div>

                    <div className={'column'}>
                        <div className="box notification is-info">
                            <div className="heading">Kopējā peļņa</div>
                            <div className="title">{convertPrice(allTimeProfitInCents)}</div>

                        </div>
                    </div>

                    <div className={'column'}>
                        <div className="box notification is-success">
                            <div className="heading">Peļņas koificents</div>
                            <div className="title">{partner.profit_percent}&#37;</div>
                            <small>Jūs pelnīsiet <b>{partner.profit_percent}&#37;</b> no katra piesaistītā klienta
                                veiktajiem pasūtījumiem.</small>
                        </div>
                    </div>


                </div>

                <PromoCode customPromoCodeStatus={customPromoCodeStatus} defaultPromoCode={defaultPromoCode}
                           submitDisabled={submitDisabled} setPromoCode={setPromoCode}
                           handleSubmitPromoCode={handleSubmitPromoCode} copyPromoToClipboard={copyPromoToClipboard}/>


                {<div className={'columns'}>
                    <PayoutSettings partner={partner} selectedPayoutOption={selectedPayoutOption}
                                    handleSubmitPayoutSettings={handleSubmitPayoutSettings}/>
                </div>}


                <div className={'columns'}>

                    <div className={'column'}>
                        <Accumulation withDrawRequestAmount={withDrawRequestAmount} setWithDrawRequestAmount={setWithDrawRequestAmount}  handleWithDrawCredits={handleWithDrawCredits} canPayoutWashCarCredits={canPayoutWashCarCredits} user={user}/>
                    </div>
                </div>


            </div>}


        </Layout>
    )
}

export default Dashboard

/*


 <LegalContract />
  <PrivateContract />
 */

/*

We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click Save and continue to set up your payments on Stripe.

      <div className="s9-widget-wrapper"></div>
 */