import React from "react";

const AccIsNotConnected = (props) => {

    return (
        <div className={'box'}>
            <p>Lai sasaistītu partnera kontu ar klienta kontu, reģistrējaties WashCar mobilajā lietotnē vai kā biznesa klients ar Jūsu norādīto telefona numuru.</p>
       <b>+{ props.user.phone_with_code }</b>
        </div>
    )

};

export default AccIsNotConnected;
