import React from "react";

const PayoutSettings = (props) => {

    return (
        <div className={'column'}>

            <form className={'box'}>
                <div className="heading">Peļņas izmaksas iestatījumi</div>

                <div className="control">

                    <label className="radio">
                        <input  checked={props.selectedPayoutOption === 'manual'} onChange={props.handleSubmitPayoutSettings} value="manual" type="radio" name="payout_option" />
                        Manuāla peļņas izmaksa. Izvēlietes šo variantu, ja vēlaties daļu vai visu peļņu konvertēt uz WashCar kredītiem.
                    </label>
                    <br/>
                    <label className="radio mr-2">
                        <input disabled={parseInt(props.partner.is_stripe_connected) === 0 } checked={props.selectedPayoutOption === 'auto'} onChange={props.handleSubmitPayoutSettings} value="auto" type="radio" name="payout_option"/>
                        Automātiska peļņas izmaksa uz bankas kontu reizi mēnesī.
                    </label>


                </div>

            </form>
        </div>
    )

};

export default PayoutSettings;