import React from "react";

const CustomPromoCode = (props) => {

    return (
<div className={'notification is-warning'}>

    <form onSubmit={props.handleSubmitPromoCode}>

        <small></small>


        <div className={'columns'}>

            <div className={'column'}>
                <div className="field">
                    <label className="label">Vēlaties personalizēto promo kodu?</label>
                    <small>Jums ir iespēja izveidot personalizēto promo kodu, piemēram, "MANASFIRMASVARDS"</small>
                    <div className="control">
                        <input required className="input" type="text"    value={props.promoCode}   onChange={(e) => props.setPromoCode(e.target.value)}/>
                    </div>
                </div>
            </div>


        </div>


        <div className="field is-grouped mt-4">
            <div className="control">
                <button disabled={props.submitDisabled} type="submit" className="button is-link">Iesniegt</button>
            </div>
        </div>

    </form>

</div>
    )

};

export default CustomPromoCode;
