import React from "react";
import AccIsConnected from "./acc-is-connected";
import AccIsNotConnected from "./acc-not-connected";


const Accumulation = (props) => {

  return (

      <div className="box notification">
        <div className="heading">Konvertācija</div>
        <div className="notification is-warning mb-2">Jums ir iespēja konvertēt uzkrājumu no <b>EUR</b> uz <b>WashCar kredītiem </b>attiecībā <b>1:2</b>!</div>
        {props.user.email === null ?  <AccIsNotConnected user={props.user}    />   :   <AccIsConnected withDrawRequestAmount={props.withDrawRequestAmount} setWithDrawRequestAmount={props.setWithDrawRequestAmount} handleWithDrawCredits={props.handleWithDrawCredits} canPayoutWashCarCredits={props.canPayoutWashCarCredits} user={props.user} /> }
      </div>

  );
};

export default Accumulation;
