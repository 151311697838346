import React from "react";
import { Link } from "gatsby"

const Contract = () => {

    return (
       <div>
           <div className={'columns'}>

               <div className={'column'}>
                   <div className={'box notification is-warning'}>

                       <p>Lai varētu veikt peļņas izmaksu EUR, jums ir jāapstiprina, ka piekrītat līguma nosacījumiem!</p>

                       <Link className={'button is-link mt-2'} to="/agreement">Uz līguma apstiprināšanu</Link>
                   </div>
               </div>


           </div>

       </div>
    )

};

export default Contract;